const PrivacyPolicy = () => {
    return (
        <>
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        "\n  [data-custom-class='body'], [data-custom-class='body'] * {\n          background: transparent !important;\n        }\n[data-custom-class='title'], [data-custom-class='title'] * {\n          font-family: Arial !important;\nfont-size: 26px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {\n          font-family: Arial !important;\ncolor: #595959 !important;\nfont-size: 14px !important;\n        }\n[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {\n          font-family: Arial !important;\nfont-size: 19px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {\n          font-family: Arial !important;\nfont-size: 17px !important;\ncolor: #000000 !important;\n        }\n[data-custom-class='body_text'], [data-custom-class='body_text'] * {\n          color: #595959 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\n        }\n[data-custom-class='link'], [data-custom-class='link'] * {\n          color: #3030F1 !important;\nfont-size: 14px !important;\nfont-family: Arial !important;\nword-break: break-word !important;\n        }\n"
                }}
            />
            <span
                style={{
                    display: "block",
                    margin: "0 auto 3.125rem",
                    width: "11.125rem",
                    height: "2.375rem",
                    background: "url(data:image/svg+xml"
                }}
            />
            <div data-custom-class="body">
                <div>
                    <strong>
                        <span style={{ fontSize: 26 }}>
                            <span data-custom-class="title">
                                <bdt className="block-component" />
                                <bdt className="question">PRIVACY POLICY</bdt>
                                <bdt className="statement-end-if-in-editor" />
                            </span>
                        </span>
                    </strong>
                </div>
                <div>
                    <br />
                </div>
                <div>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                        <strong>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="subtitle">
                                    Last updated <bdt className="question">January 15, 2024</bdt>
                                </span>
                            </span>
                        </strong>
                    </span>
                </div>
                <div>
                    <br />
                </div>
                <div>
                    <br />
                </div>
                <div>
                    <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                    <span style={{ color: "rgb(127, 127, 127)" }}>
                        <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                This privacy notice for{" "}
                                <bdt className="question">
                                    Assets Global
                                    <bdt className="block-component" />
                                </bdt>{" "}
                                (<bdt className="block-component" />"<strong>we</strong>," "
                                <strong>us</strong>," or "<strong>our</strong>"
                                <bdt className="statement-end-if-in-editor" />
                            </span>
                            <span data-custom-class="body_text">
                                ), describes how and why we might collect, store, use, and/or share
                                (<bdt className="block-component" />"<strong>process</strong>"
                                <bdt className="statement-end-if-in-editor" />) your information
                                when you use our services (<bdt className="block-component" />"
                                <strong>Services</strong>"
                                <bdt className="statement-end-if-in-editor" />
                                ), such as when you:
                            </span>
                        </span>
                    </span>
                    <span style={{ fontSize: 15 }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span data-custom-class="body_text">
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                        </span>
                    </span>
                </div>
                <ul>
                    <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    Visit our website
                                    <bdt className="block-component" /> at{" "}
                                    <span style={{ color: "#7ab945" }}>
                                        <bdt className="question">
                                            <a
                                                href="https://assetsglobal.in/"
                                                target="_blank"
                                                data-custom-class="link"
                                            >
                                                https://assetsglobal.in/
                                            </a>
                                        </bdt>
                                    </span>
                                    <span style={{ fontSize: 15 }}>
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ fontSize: 15 }}>
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt className="statement-end-if-in-editor">
                                                            , or any website of ours that links to this privacy
                                                            notice
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </li>
                </ul>
                <div>
                    <bdt className="block-component">
                        <span style={{ fontSize: 15 }}>
                            <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </bdt>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        Engage with us in other related ways, including any sales,
                                        marketing, or events
                                        <span style={{ fontSize: 15 }}>
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <span data-custom-class="body_text">
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <bdt className="statement-end-if-in-editor" />
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span data-custom-class="body_text">
                                    <strong>Questions or concerns?&nbsp;</strong>Reading this privacy
                                    notice will help you understand your privacy rights and choices.
                                    If you do not agree with our policies and practices, please do not
                                    use our Services.
                                    <bdt className="block-component" /> If you still have any
                                    questions or concerns, please contact us at{" "}
                                    <bdt className="question">info@assetsglobal.in</bdt>.
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <strong>
                            <span style={{ fontSize: 15 }}>
                                <span data-custom-class="heading_1">SUMMARY OF KEY POINTS</span>
                            </span>
                        </strong>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>
                                    <em>
                                        This summary provides key points from our privacy notice, but
                                        you can find out more details about any of these topics by
                                        clicking the link following each key point or by using our&nbsp;
                                    </em>
                                </strong>
                            </span>
                        </span>
                        <a data-custom-class="link" href="#toc">
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    <strong>
                                        <em>table of contents</em>
                                    </strong>
                                </span>
                            </span>
                        </a>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>
                                    <em>&nbsp;below to find the section you are looking for.</em>
                                </strong>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>What personal information do we process?</strong> When you
                                visit, use, or navigate our Services, we may process personal
                                information depending on how you interact with us and the Services,
                                the choices you make, and the products and features you use. Learn
                                more about&nbsp;
                            </span>
                        </span>
                        <a data-custom-class="link" href="#personalinfo">
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    personal information you disclose to us
                                </span>
                            </span>
                        </a>
                        <span data-custom-class="body_text">.</span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>Do we process any sensitive personal information?</strong>{" "}
                                <bdt className="block-component" />
                                We do not process sensitive personal information.
                                <bdt className="else-block" />
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>Do we collect any information from third parties?</strong>{" "}
                                <bdt className="block-component" />
                                We may collect information from public databases, marketing
                                partners, social media platforms, and other outside sources. Learn
                                more about&nbsp;
                            </span>
                        </span>
                        <a data-custom-class="link" href="#othersources">
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    information collected from other sources
                                </span>
                            </span>
                        </a>
                        <span data-custom-class="body_text">.</span>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <bdt className="statement-end-if-in-editor" />
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>How do we process your information?</strong> We process your
                                information to provide, improve, and administer our Services,
                                communicate with you, for security and fraud prevention, and to
                                comply with law. We may also process your information for other
                                purposes with your consent. We process your information only when we
                                have a valid legal reason to do so. Learn more about&nbsp;
                            </span>
                        </span>
                        <a data-custom-class="link" href="#infouse">
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    how we process your information
                                </span>
                            </span>
                        </a>
                        <span data-custom-class="body_text">.</span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>
                                    In what situations and with which{" "}
                                    <bdt className="block-component" />
                                    parties do we share personal information?
                                </strong>{" "}
                                We may share information in specific situations and with specific{" "}
                                <bdt className="block-component" />
                                third parties. Learn more about&nbsp;
                            </span>
                        </span>
                        <a data-custom-class="link" href="#whoshare">
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    when and with whom we share your personal information
                                </span>
                            </span>
                        </a>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                .<bdt className="block-component" />
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>How do we keep your information safe?</strong> We have{" "}
                                <bdt className="block-component" />
                                organizational
                                <bdt className="statement-end-if-in-editor" /> and technical
                                processes and procedures in place to protect your personal
                                information. However, no electronic transmission over the internet
                                or information storage technology can be guaranteed to be 100%
                                secure, so we cannot promise or guarantee that hackers,
                                cybercriminals, or other <bdt className="block-component" />
                                unauthorized
                                <bdt className="statement-end-if-in-editor" /> third parties will
                                not be able to defeat our security and improperly collect, access,
                                steal, or modify your information. Learn more about&nbsp;
                            </span>
                        </span>
                        <a data-custom-class="link" href="#infosafe">
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    how we keep your information safe
                                </span>
                            </span>
                        </a>
                        <span data-custom-class="body_text">.</span>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <bdt className="statement-end-if-in-editor" />
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>What are your rights?</strong> Depending on where you are
                                located geographically, the applicable privacy law may mean you have
                                certain rights regarding your personal information. Learn more
                                about&nbsp;
                            </span>
                        </span>
                        <a data-custom-class="link" href="#privacyrights">
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                <span data-custom-class="body_text">your privacy rights</span>
                            </span>
                        </a>
                        <span data-custom-class="body_text">.</span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>How do you exercise your rights?</strong> The easiest way to
                                exercise your rights is by{" "}
                                <bdt className="block-component">submitting a&nbsp;</bdt>
                            </span>
                        </span>
                        <a
                            data-custom-class="link"
                            href="https://app.termly.io/notify/36536147-0581-4733-ab97-8f6860f48065"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    data subject access request
                                </span>
                            </span>
                        </a>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <bdt className="block-component" />, or by contacting us. We will
                                consider and act upon any request in accordance with applicable data
                                protection laws.
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                Want to learn more about what we do with any information we
                                collect?&nbsp;
                            </span>
                        </span>
                        <a data-custom-class="link" href="#toc">
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                <span data-custom-class="body_text">
                                    Review the privacy notice in full
                                </span>
                            </span>
                        </a>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">.</span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div id="toc" style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(0, 0, 0)" }}>
                                    <strong>
                                        <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
                                    </strong>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <a href="#infocollect" style={{ color: "#7ab945", textDecoration: 'none' }}>
                                <span className="span-policy" style={{ color: "#7ab945 !important" }}>
                                    1. WHAT INFORMATION DO WE COLLECT?
                                </span>
                            </a>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#infouse">
                                <span style={{ color: "#7ab945" }}>
                                    2. HOW DO WE PROCESS YOUR INFORMATION?
                                    <bdt className="block-component" />
                                </span>
                            </a>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span style={{ color: "#7ab945" }}>
                                <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#whoshare">
                                    3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                                </a>
                            </span>
                            <span data-custom-class="body_text">
                                <bdt className="block-component" />
                                <span style={{ color: "rgb(127, 127, 127)" }}>
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#cookies">
                                <span style={{ color: "#7ab945" }}>
                                    4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                                </span>
                            </a>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <bdt className="statement-end-if-in-editor" />
                                        </span>
                                    </span>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <bdt className="block-component" />
                                                </span>
                                            </span>
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#inforetain">
                                <span style={{ color: "#7ab945" }}>
                                    5. HOW LONG DO WE KEEP YOUR INFORMATION?
                                </span>
                            </a>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#infosafe">
                                <span style={{ color: "#7ab945" }}>
                                    6. HOW DO WE KEEP YOUR INFORMATION SAFE?
                                </span>
                            </a>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <bdt className="statement-end-if-in-editor" />
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#infominors">
                                <span style={{ color: "#7ab945" }}>
                                    7. DO WE COLLECT INFORMATION FROM MINORS?
                                </span>
                            </a>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                            <bdt className="statement-end-if-in-editor" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span style={{ color: "#7ab945" }}>
                                <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#privacyrights">
                                    8. WHAT ARE YOUR PRIVACY RIGHTS?
                                </a>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#DNT">
                                <span style={{ color: "#7ab945" }}>
                                    9. CONTROLS FOR DO-NOT-TRACK FEATURES
                                    <bdt className="block-component" />
                                </span>
                                <bdt className="block-component">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text" />
                                    </span>
                                </bdt>
                            </a>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#DNT">
                            <bdt className="block-component">
                                <span style={{ fontSize: 15 }} />
                            </bdt>
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                            <bdt className="block-component" />
                        </a>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <a data-custom-class="link" href="#DNT">
                            <span style={{ fontSize: 15 }} />
                        </a>
                        <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#policyupdates">
                            <span style={{ color: "#7ab945" }}>
                                10. DO WE MAKE UPDATES TO THIS NOTICE?
                            </span>
                        </a>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#contact">
                            <span style={{ color: "#7ab945", fontSize: 15 }}>
                                11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                            </span>
                        </a>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <a style={{ color: "#7ab945", textDecoration: 'none' }} href="#request">
                            <span style={{ color: "#7ab945" }}>
                                12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
                                YOU?
                            </span>
                        </a>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div id="infocollect" style={{ lineHeight: "1.5" }}>
                        <span style={{ color: "rgb(0, 0, 0)" }}>
                            <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                                <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                                    <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                            <strong>
                                                <span data-custom-class="heading_1">
                                                    1. WHAT INFORMATION DO WE COLLECT?
                                                </span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div id="personalinfo" style={{ lineHeight: "1.5" }}>
                        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
                            <span style={{ fontSize: 15 }}>
                                <strong>Personal information you disclose to us</strong>
                            </span>
                        </span>
                    </div>
                    <div>
                        <div>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span data-custom-class="body_text">
                                                    <strong>
                                                        <em>In Short:</em>
                                                    </strong>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span data-custom-class="body_text">
                                                    <strong>
                                                        <em>&nbsp;</em>
                                                    </strong>
                                                    <em>
                                                        We collect personal information that you provide to us.
                                                    </em>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    We collect personal information that you voluntarily provide to us
                                    when you{" "}
                                    <span style={{ fontSize: 15 }}>
                                        <bdt className="block-component" />
                                    </span>
                                    register on the Services,&nbsp;
                                </span>
                                <span style={{ fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                            <bdt className="statement-end-if-in-editor" />
                                        </span>
                                    </span>
                                    <span data-custom-class="body_text">
                                        express an interest in obtaining information about us or our
                                        products and Services, when you participate in activities on the
                                        Services, or otherwise when you contact us.
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <strong>Personal Information Provided by You.</strong> The
                                    personal information that we collect depends on the context of
                                    your interactions with us and the Services, the choices you make,
                                    and the products and features you use. The personal information we
                                    collect may include the following:
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="forloop-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="question">names</bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="forloop-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="question">phone numbers</bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="forloop-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="question">email addresses</bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="forloop-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="question">job titles</bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="forloop-component" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="question">usernames</bdt>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="forloop-component" />
                                        </span>
                                        <span data-custom-class="body_text">
                                            <bdt className="statement-end-if-in-editor" />
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div id="sensitiveinfo" style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <strong>Sensitive Information.</strong>{" "}
                                <bdt className="block-component" />
                                We do not process sensitive information.
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <bdt className="else-block" />
                            </span>
                        </span>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="block-component">
                                                <bdt className="block-component" />
                                            </bdt>
                                        </span>
                                    </span>
                                </span>
                            </span>
                            <bdt className="block-component" />
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    All personal information that you provide to us must be true,
                                    complete, and accurate, and you must notify us of any changes to
                                    such personal information.
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                </span>
                                <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                        <span data-custom-class="body_text">
                                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                                <span data-custom-class="body_text">
                                                    <bdt className="statement-end-if-in-editor">
                                                        <bdt className="block-component" />
                                                    </bdt>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div id="othersources" style={{ lineHeight: "1.5" }}>
                        <span data-custom-class="heading_2" style={{ color: "rgb(0, 0, 0)" }}>
                            <span style={{ fontSize: 15 }}>
                                <strong>Information collected from other sources</strong>
                            </span>
                        </span>
                    </div>
                    <div>
                        <div>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span data-custom-class="body_text">
                                                    <strong>
                                                        <em>In Short:&nbsp;</em>
                                                    </strong>
                                                    <em>
                                                        We may collect limited data from public databases,
                                                        marketing partners,{" "}
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ fontSize: 15 }}>
                                                                <em>
                                                                    <span data-custom-class="body_text">
                                                                        <bdt className="block-component" />
                                                                    </span>
                                                                </em>
                                                            </span>
                                                        </span>
                                                        and other outside sources.
                                                    </em>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    In order to enhance our ability to provide relevant marketing,
                                    offers, and services to you and update our records, we may obtain
                                    information about you from other sources, such as public
                                    databases, joint marketing partners, affiliate programs, data
                                    providers,
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                        &nbsp;
                                    </span>
                                    and from other third parties. This information includes mailing
                                    addresses, job titles, email addresses, phone numbers, intent data
                                    (or user <bdt className="block-component" />
                                    behavior
                                    <bdt className="statement-end-if-in-editor" /> data), Internet
                                    Protocol (IP) addresses, social media profiles, social media URLs,
                                    and custom profiles, for purposes of targeted advertising and
                                    event promotion.
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text">
                                                            <bdt className="block-component" />
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 15 }}>
                                            <span data-custom-class="body_text">
                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                        <bdt className="block-component">
                                                            <span
                                                                style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                                                            >
                                                                <span data-custom-class="body_text">
                                                                    <span
                                                                        style={{
                                                                            color: "rgb(89, 89, 89)",
                                                                            fontSize: 15
                                                                        }}
                                                                    >
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="statement-end-if-in-editor">
                                                                                <bdt className="statement-end-if-in-editor" />
                                                                            </bdt>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </bdt>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                        <span style={{ fontSize: 15 }}>
                            <span data-custom-class="body_text">
                                <bdt className="block-component" />
                            </span>
                        </span>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div id="infouse" style={{ lineHeight: "1.5" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                                            <strong>
                                                <span data-custom-class="heading_1">
                                                    2. HOW DO WE PROCESS YOUR INFORMATION?
                                                </span>
                                            </strong>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </span>
                    </div>
                    <div>
                        <div style={{ lineHeight: "1.5" }}>
                            <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                                    <span data-custom-class="body_text">
                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span data-custom-class="body_text">
                                                    <strong>
                                                        <em>In Short:&nbsp;</em>
                                                    </strong>
                                                    <em>
                                                        We process your information to provide, improve, and
                                                        administer our Services, communicate with you, for
                                                        security and fraud prevention, and to comply with law.
                                                        We may also process your information for other purposes
                                                        with your consent.
                                                    </em>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </div>
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <br />
                    </div>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <strong>
                                        We process your personal information for a variety of reasons,
                                        depending on how you interact with our Services, including:
                                    </strong>
                                    <bdt className="block-component" />
                                </span>
                            </span>
                        </span>
                    </div>
                    <ul>
                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <strong>
                                            To facilitate account creation and authentication and
                                            otherwise manage user accounts.&nbsp;
                                        </strong>
                                        We may process your information so you can create and log in to
                                        your account, as well as keep your account in working order.
                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span data-custom-class="body_text">
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <span data-custom-class="body_text">
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span data-custom-class="body_text">
                                                                            <bdt className="statement-end-if-in-editor" />
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    </ul>
                    <div style={{ lineHeight: "1.5" }}>
                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span data-custom-class="body_text">
                                    <bdt className="block-component" />
                                </span>
                            </span>
                        </span>
                        <div style={{ lineHeight: "1.5" }}>
                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span data-custom-class="body_text">
                                        <bdt className="block-component" />
                                    </span>
                                </span>
                            </span>
                            <div style={{ lineHeight: "1.5" }}>
                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                        <span data-custom-class="body_text">
                                            <bdt className="block-component" />
                                        </span>
                                    </span>
                                </span>
                                <div style={{ lineHeight: "1.5" }}>
                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                        <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                            <span data-custom-class="body_text">
                                                <bdt className="block-component" />
                                            </span>
                                        </span>
                                    </span>
                                    <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                            <span style={{ fontSize: 15 }} />
                                        </bdt>
                                    </div>
                                    <ul>
                                        <li data-custom-class="body_text" style={{ lineHeight: "1.5" }}>
                                            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span data-custom-class="body_text">
                                                        <strong>
                                                            To send administrative information to you.&nbsp;
                                                        </strong>
                                                        We may process your information to send you details
                                                        about our products and services, changes to our terms
                                                        and policies, and other similar information.
                                                        <span
                                                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                        >
                                                            <span
                                                                style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                            >
                                                                <span data-custom-class="body_text">
                                                                    <bdt className="statement-end-if-in-editor" />
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </span>
                                        </li>
                                    </ul>
                                    <div style={{ lineHeight: "1.5" }}>
                                        <bdt className="block-component">
                                            <span style={{ fontSize: 15 }} />
                                        </bdt>
                                        <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                                <span style={{ fontSize: 15 }} />
                                            </bdt>
                                        </div>
                                        <ul>
                                            <li
                                                data-custom-class="body_text"
                                                style={{ lineHeight: "1.5" }}
                                            >
                                                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                                                        <span data-custom-class="body_text">
                                                            <strong>
                                                                To <bdt className="block-component" />
                                                                fulfill
                                                                <bdt className="statement-end-if-in-editor" /> and
                                                                manage your orders.&nbsp;
                                                            </strong>
                                                            We may process your information to{" "}
                                                            <bdt className="block-component" />
                                                            fulfill
                                                            <bdt className="statement-end-if-in-editor" /> and
                                                            manage your orders, payments, returns, and exchanges
                                                            made through the Services.
                                                            <span
                                                                style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                            >
                                                                <span
                                                                    style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                                                                >
                                                                    <span data-custom-class="body_text">
                                                                        <span
                                                                            style={{
                                                                                fontSize: 15,
                                                                                color: "rgb(89, 89, 89)"
                                                                            }}
                                                                        >
                                                                            <span
                                                                                style={{
                                                                                    fontSize: 15,
                                                                                    color: "rgb(89, 89, 89)"
                                                                                }}
                                                                            >
                                                                                <span data-custom-class="body_text">
                                                                                    <span
                                                                                        style={{
                                                                                            fontSize: 15,
                                                                                            color: "rgb(89, 89, 89)"
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            style={{
                                                                                                fontSize: 15,
                                                                                                color: "rgb(89, 89, 89)"
                                                                                            }}
                                                                                        >
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </span>
                                            </li>
                                        </ul>
                                        <div style={{ lineHeight: "1.5" }}>
                                            <bdt className="block-component">
                                                <span style={{ fontSize: 15 }}>
                                                    <span data-custom-class="body_text" />
                                                </span>
                                            </bdt>
                                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                    <span style={{ fontSize: 15 }} />
                                                </bdt>
                                            </p>
                                            <ul>
                                                <li
                                                    data-custom-class="body_text"
                                                    style={{ lineHeight: "1.5" }}
                                                >
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>
                                                                        To enable user-to-user communications.&nbsp;
                                                                    </strong>
                                                                    We may process your information if you choose to
                                                                    use any of our offerings that allow for
                                                                    communication with another user.
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <span
                                                                                            style={{ color: "rgb(89, 89, 89)" }}
                                                                                        >
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                    <span style={{ fontSize: 15 }} />
                                                </bdt>
                                            </p>
                                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                <bdt className="block-component" />
                                            </p>
                                            <ul>
                                                <li
                                                    data-custom-class="body_text"
                                                    style={{ lineHeight: "1.5" }}
                                                >
                                                    <span style={{ fontSize: 15 }}>
                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                            <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                <span data-custom-class="body_text">
                                                                    <strong>To request feedback.&nbsp;</strong>We may
                                                                    process your information when necessary to request
                                                                    feedback and to contact you about your use of our
                                                                    Services.
                                                                    <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                        <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                                                                    <span data-custom-class="body_text">
                                                                                        <span
                                                                                            style={{ color: "rgb(89, 89, 89)" }}
                                                                                        >
                                                                                            <span data-custom-class="body_text">
                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                            </span>
                                                                                        </span>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </span>
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                            <p style={{ fontSize: 15, lineHeight: "1.5" }}>
                                                <bdt className="block-component" />
                                            </p>
                                            <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                    <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text" />
                                                    </span>
                                                </bdt>
                                            </div>
                                            <ul>
                                                <li
                                                    data-custom-class="body_text"
                                                    style={{ lineHeight: "1.5" }}
                                                >
                                                    <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text">
                                                            <strong>
                                                                To send you marketing and promotional
                                                                communications.&nbsp;
                                                            </strong>
                                                            We may process the personal information you send to us
                                                            for our marketing purposes, if this is in accordance
                                                            with your marketing preferences. You can opt out of
                                                            our marketing emails at any time. For more
                                                            information, see <bdt className="block-component" />"
                                                            <bdt className="statement-end-if-in-editor" />
                                                        </span>
                                                    </span>
                                                    <a href="#privacyrights">
                                                        <span data-custom-class="link">
                                                            <span
                                                                style={{ color: "#7ab945", fontSize: 15 }}
                                                            >
                                                                <span data-custom-class="link">
                                                                    WHAT ARE YOUR PRIVACY RIGHTS?
                                                                </span>
                                                            </span>
                                                        </span>
                                                    </a>
                                                    <span style={{ fontSize: 15 }}>
                                                        <span data-custom-class="body_text">
                                                            <bdt className="block-component" />"
                                                            <bdt className="statement-end-if-in-editor" /> below.
                                                        </span>
                                                        <bdt className="statement-end-if-in-editor">
                                                            <span data-custom-class="body_text" />
                                                        </bdt>
                                                    </span>
                                                </li>
                                            </ul>
                                            <div style={{ lineHeight: "1.5" }}>
                                                <bdt className="block-component">
                                                    <span style={{ fontSize: 15 }} />
                                                </bdt>
                                                <div style={{ lineHeight: "1.5" }}>
                                                    <bdt className="block-component">
                                                        <span style={{ fontSize: 15 }} />
                                                    </bdt>
                                                    <div style={{ lineHeight: "1.5" }}>
                                                        <span style={{ fontSize: 15 }}>
                                                            <bdt className="block-component">
                                                                <span data-custom-class="body_text" />
                                                            </bdt>
                                                        </span>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                            <bdt className="block-component">
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                            </bdt>
                                                        </div>
                                                        <ul>
                                                            <li
                                                                data-custom-class="body_text"
                                                                style={{ lineHeight: "1.5" }}
                                                            >
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span data-custom-class="body_text">
                                                                        <strong>To post testimonials.</strong> We post
                                                                        testimonials on our Services that may contain
                                                                        personal information.
                                                                        <bdt className="statement-end-if-in-editor" />
                                                                    </span>
                                                                </span>
                                                            </li>
                                                        </ul>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                            <bdt className="block-component">
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                            </bdt>
                                                        </div>
                                                        <ul>
                                                            <li
                                                                data-custom-class="body_text"
                                                                style={{ lineHeight: "1.5" }}
                                                            >
                                                                <span data-custom-class="body_text">
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <strong>To protect our Services.</strong> We may
                                                                        process your information as part of our efforts
                                                                        to keep our Services safe and secure, including
                                                                        fraud monitoring and prevention.
                                                                    </span>
                                                                </span>
                                                                <bdt className="statement-end-if-in-editor">
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text" />
                                                                    </span>
                                                                </bdt>
                                                            </li>
                                                        </ul>
                                                        <div style={{ lineHeight: "1.5" }}>
                                                            <bdt className="block-component">
                                                                <span style={{ fontSize: 15 }}>
                                                                    <span data-custom-class="body_text" />
                                                                </span>
                                                            </bdt>
                                                            <div style={{ lineHeight: "1.5" }}>
                                                                <bdt className="block-component">
                                                                    <span style={{ fontSize: 15 }}>
                                                                        <span data-custom-class="body_text" />
                                                                    </span>
                                                                </bdt>
                                                                <div style={{ lineHeight: "1.5" }}>
                                                                    <bdt className="block-component">
                                                                        <span style={{ fontSize: 15 }}>
                                                                            <span data-custom-class="body_text" />
                                                                        </span>
                                                                    </bdt>
                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                        <bdt className="block-component">
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text" />
                                                                            </span>
                                                                        </bdt>
                                                                    </div>
                                                                    <ul>
                                                                        <li
                                                                            data-custom-class="body_text"
                                                                            style={{ lineHeight: "1.5" }}
                                                                        >
                                                                            <span data-custom-class="body_text">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <strong>To identify usage trends.</strong>{" "}
                                                                                    We may process information about how you
                                                                                    use our Services to better understand how
                                                                                    they are being used so we can improve
                                                                                    them.
                                                                                </span>
                                                                            </span>
                                                                            <bdt className="statement-end-if-in-editor">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span data-custom-class="body_text" />
                                                                                </span>
                                                                            </bdt>
                                                                        </li>
                                                                    </ul>
                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                        <bdt className="block-component">
                                                                            <span style={{ fontSize: 15 }}>
                                                                                <span data-custom-class="body_text" />
                                                                            </span>
                                                                        </bdt>
                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                            <bdt className="block-component">
                                                                                <span style={{ fontSize: 15 }}>
                                                                                    <span data-custom-class="body_text" />
                                                                                </span>
                                                                            </bdt>
                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                <bdt className="block-component">
                                                                                    <span style={{ fontSize: 15 }}>
                                                                                        <span data-custom-class="body_text" />
                                                                                    </span>
                                                                                </bdt>
                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                    <bdt className="block-component">
                                                                                        <span style={{ fontSize: 15 }}>
                                                                                            <span data-custom-class="body_text" />
                                                                                        </span>
                                                                                    </bdt>
                                                                                    <div style={{ lineHeight: "1.5" }}>
                                                                                        <bdt className="block-component">
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text" />
                                                                                            </span>
                                                                                        </bdt>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <bdt className="block-component">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text" />
                                                                                                </span>
                                                                                            </bdt>
                                                                                            <bdt className="block-component">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text" />
                                                                                                </span>
                                                                                            </bdt>
                                                                                            <bdt className="block-component">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text" />
                                                                                                </span>
                                                                                            </bdt>
                                                                                            <bdt className="block-component">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text" />
                                                                                                </span>
                                                                                            </bdt>
                                                                                        </div>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <br />
                                                                                        </div>
                                                                                        <div
                                                                                            id="whoshare"
                                                                                            style={{ lineHeight: "1.5" }}
                                                                                        >
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "rgb(127, 127, 127)"
                                                                                                }}
                                                                                            >
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "rgb(89, 89, 89)",
                                                                                                        fontSize: 15
                                                                                                    }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                id="control"
                                                                                                                style={{
                                                                                                                    color: "rgb(0, 0, 0)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <strong>
                                                                                                                    <span data-custom-class="heading_1">
                                                                                                                        3. WHEN AND WITH WHOM DO
                                                                                                                        WE SHARE YOUR PERSONAL
                                                                                                                        INFORMATION?
                                                                                                                    </span>
                                                                                                                </strong>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <br />
                                                                                        </div>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <strong>
                                                                                                            <em>In Short:</em>
                                                                                                        </strong>
                                                                                                        <em>
                                                                                                            &nbsp;We may share information
                                                                                                            in specific situations
                                                                                                            described in this section
                                                                                                            and/or with the following{" "}
                                                                                                            <bdt className="block-component" />
                                                                                                            third parties.
                                                                                                        </em>
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <span
                                                                                                style={{
                                                                                                    fontSize: 15,
                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                }}
                                                                                            >
                                                                                                <span
                                                                                                    style={{
                                                                                                        fontSize: 15,
                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                    }}
                                                                                                >
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <bdt className="block-component" />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <br />
                                                                                        </div>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    We{" "}
                                                                                                    <bdt className="block-component" />
                                                                                                    may need to share your personal
                                                                                                    information in the following
                                                                                                    situations:
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                            <li
                                                                                                data-custom-class="body_text"
                                                                                                style={{ lineHeight: "1.5" }}
                                                                                            >
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <strong>
                                                                                                            Business Transfers.
                                                                                                        </strong>{" "}
                                                                                                        We may share or transfer your
                                                                                                        information in connection with,
                                                                                                        or during negotiations of, any
                                                                                                        merger, sale of company assets,
                                                                                                        financing, or acquisition of all
                                                                                                        or a portion of our business to
                                                                                                        another company.
                                                                                                    </span>
                                                                                                </span>
                                                                                            </li>
                                                                                        </ul>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <span data-custom-class="body_text">
                                                                                                    <bdt className="block-component" />
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                            <li
                                                                                                data-custom-class="body_text"
                                                                                                style={{ lineHeight: "1.5" }}
                                                                                            >
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <strong>
                                                                                                            When we use Google Maps
                                                                                                            Platform APIs.
                                                                                                        </strong>{" "}
                                                                                                        We may share your information
                                                                                                        with certain Google Maps
                                                                                                        Platform APIs (e.g.
                                                                                                        <bdt className="block-component" />
                                                                                                        ,
                                                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                        Google Maps API, Places API).
                                                                                                    </span>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <bdt className="block-component" />
                                                                                                        <bdt className="block-component" />
                                                                                                        <bdt className="block-component" />
                                                                                                    </span>
                                                                                                </span>
                                                                                            </li>
                                                                                        </ul>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <span style={{ fontSize: 15 }}>
                                                                                                <bdt className="block-component">
                                                                                                    <span data-custom-class="body_text" />
                                                                                                </bdt>
                                                                                            </span>
                                                                                        </div>
                                                                                        <ul>
                                                                                            <li
                                                                                                data-custom-class="body_text"
                                                                                                style={{ lineHeight: "1.5" }}
                                                                                            >
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <strong>
                                                                                                            Affiliates.&nbsp;
                                                                                                        </strong>
                                                                                                        We may share your information
                                                                                                        with our affiliates, in which
                                                                                                        case we will require those
                                                                                                        affiliates to{" "}
                                                                                                        <bdt className="block-component" />
                                                                                                        honor
                                                                                                        <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                        this privacy notice. Affiliates
                                                                                                        include our parent company and
                                                                                                        any subsidiaries, joint venture
                                                                                                        partners, or other companies
                                                                                                        that we control or that are
                                                                                                        under common control with us.
                                                                                                    </span>
                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                        <span data-custom-class="body_text" />
                                                                                                    </bdt>
                                                                                                </span>
                                                                                            </li>
                                                                                        </ul>
                                                                                        <div style={{ lineHeight: "1.5" }}>
                                                                                            <bdt className="block-component">
                                                                                                <span style={{ fontSize: 15 }}>
                                                                                                    <span data-custom-class="body_text" />
                                                                                                </span>
                                                                                            </bdt>
                                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                                <bdt className="block-component">
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text" />
                                                                                                    </span>
                                                                                                </bdt>
                                                                                            </div>
                                                                                            <ul>
                                                                                                <li
                                                                                                    data-custom-class="body_text"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <strong>Other Users.</strong>{" "}
                                                                                                            When you share personal
                                                                                                            information
                                                                                                            <bdt className="block-component" />{" "}
                                                                                                            (for example, by posting
                                                                                                            comments, contributions, or
                                                                                                            other content to the Services)
                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                            or otherwise interact with
                                                                                                            public areas of the Services,
                                                                                                            such personal information may
                                                                                                            be viewed by all users and may
                                                                                                            be publicly made available
                                                                                                            outside the Services in
                                                                                                            perpetuity.
                                                                                                            <bdt className="block-component" />{" "}
                                                                                                            Similarly, other users will be
                                                                                                            able to view descriptions of
                                                                                                            your activity, communicate
                                                                                                            with you within our Services,
                                                                                                            and view your profile.
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </li>
                                                                                            </ul>
                                                                                            <div style={{ lineHeight: "1.5" }}>
                                                                                                <bdt className="block-component">
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text" />
                                                                                                    </span>
                                                                                                </bdt>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <bdt className="block-component">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text" />
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(89, 89, 89)",
                                                                                                            fontSize: 15
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <bdt className="block-component">
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                <bdt className="block-component" />
                                                                                                                            </span>
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="cookies"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(127, 127, 127)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        id="control"
                                                                                                                        style={{
                                                                                                                            color: "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                4. DO WE USE COOKIES
                                                                                                                                AND OTHER TRACKING
                                                                                                                                TECHNOLOGIES?
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <strong>
                                                                                                                    <em>In Short:</em>
                                                                                                                </strong>
                                                                                                                <em>
                                                                                                                    &nbsp;We may use cookies
                                                                                                                    and other tracking
                                                                                                                    technologies to collect
                                                                                                                    and store your
                                                                                                                    information.
                                                                                                                </em>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                We may use cookies and
                                                                                                                similar tracking
                                                                                                                technologies (like web
                                                                                                                beacons and pixels) to
                                                                                                                gather information when you
                                                                                                                interact with our Services.
                                                                                                                Some online tracking
                                                                                                                technologies help us
                                                                                                                maintain the security of our
                                                                                                                Services
                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                and your account
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                , prevent crashes, fix bugs,
                                                                                                                save your preferences, and
                                                                                                                assist with basic site
                                                                                                                functions.
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                We also permit third parties
                                                                                                                and service providers to use
                                                                                                                online tracking technologies
                                                                                                                on our Services for
                                                                                                                analytics and advertising,
                                                                                                                including to help manage and
                                                                                                                display advertisements, to
                                                                                                                tailor advertisements to
                                                                                                                your interests, or to send
                                                                                                                abandoned shopping cart
                                                                                                                reminders (depending on your
                                                                                                                communication preferences).
                                                                                                                The third parties and
                                                                                                                service providers use their
                                                                                                                technology to provide
                                                                                                                advertising about products
                                                                                                                and services tailored to
                                                                                                                your interests which may
                                                                                                                appear either on our
                                                                                                                Services or on other
                                                                                                                websites.
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <bdt className="block-component">
                                                                                                        <span
                                                                                                            style={{ fontSize: 15 }}
                                                                                                        />
                                                                                                    </bdt>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                Specific information about
                                                                                                                how we use such technologies
                                                                                                                and how you can refuse
                                                                                                                certain cookies is set out
                                                                                                                in our Cookie Notice
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component" />
                                                                                                                        :{" "}
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                color:
                                                                                                                                    "#7ab945"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <bdt className="question">
                                                                                                                                <a
                                                                                                                                    href="https://assetsglobal.in/cookies"
                                                                                                                                    target="_blank"
                                                                                                                                    data-custom-class="link"
                                                                                                                                >
                                                                                                                                    https://assetsglobal.in/cookies
                                                                                                                                </a>
                                                                                                                            </bdt>
                                                                                                                        </span>
                                                                                                                        <bdt className="block-component" />
                                                                                                                        .
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <bdt className="block-component">
                                                                                                        <span
                                                                                                            style={{ fontSize: 15 }}
                                                                                                        />
                                                                                                    </bdt>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <br />
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <strong>
                                                                                                            <span data-custom-class="heading_2">
                                                                                                                Google Analytics
                                                                                                            </span>
                                                                                                        </strong>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <br />
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            We may share your information
                                                                                                            with Google Analytics to track
                                                                                                            and{" "}
                                                                                                            <bdt className="block-component" />
                                                                                                            analyze
                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                            the use of the Services.
                                                                                                            <bdt className="block-component" />{" "}
                                                                                                            The Google Analytics
                                                                                                            Advertising Features that we
                                                                                                            may use include:{" "}
                                                                                                            <bdt className="forloop-component" />
                                                                                                            <bdt className="block-component" />
                                                                                                            <bdt className="question">
                                                                                                                Remarketing with Google
                                                                                                                Analytics
                                                                                                            </bdt>
                                                                                                            <bdt className="block-component" />
                                                                                                            <bdt className="forloop-component" />
                                                                                                            <bdt className="block-component" />
                                                                                                            ,{" "}
                                                                                                            <bdt className="question">
                                                                                                                Google Analytics
                                                                                                                Demographics and Interests
                                                                                                                Reporting
                                                                                                            </bdt>
                                                                                                            <bdt className="else-block" />
                                                                                                            <bdt className="forloop-component" />
                                                                                                            <bdt className="block-component" />{" "}
                                                                                                            and{" "}
                                                                                                            <bdt className="question">
                                                                                                                Google Display Network
                                                                                                                Impressions Reporting
                                                                                                            </bdt>
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                            <bdt className="forloop-component" />
                                                                                                            .
                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                            To opt out of being tracked by
                                                                                                            Google Analytics across the
                                                                                                            Services, visit{" "}
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "#7ab945"
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    data-fr-linked="true"
                                                                                                                    href="https://tools.google.com/dlpage/gaoptout"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    target="_blank"
                                                                                                                >
                                                                                                                    https://tools.google.com/dlpage/gaoptout
                                                                                                                </a>
                                                                                                                .
                                                                                                            </span>
                                                                                                            <bdt className="block-component" />{" "}
                                                                                                            You can opt out of Google
                                                                                                            Analytics Advertising Features
                                                                                                            through{" "}
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "#7ab945"
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    data-custom-class="link"
                                                                                                                    href="https://adssettings.google.com/"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    target="_blank"
                                                                                                                >
                                                                                                                    Ads Settings
                                                                                                                </a>
                                                                                                            </span>{" "}
                                                                                                            and Ad Settings for mobile
                                                                                                            apps. Other opt out means
                                                                                                            include{" "}
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "#7ab945"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rbg(0, 58, 250)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <a
                                                                                                                        data-custom-class="link"
                                                                                                                        href="http://optout.networkadvertising.org/"
                                                                                                                        rel="noopener noreferrer"
                                                                                                                        target="_blank"
                                                                                                                    >
                                                                                                                        http://optout.networkadvertising.org/
                                                                                                                    </a>
                                                                                                                </span>
                                                                                                            </span>{" "}
                                                                                                            and{" "}
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "#7ab945"
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    data-custom-class="link"
                                                                                                                    href="http://www.networkadvertising.org/mobile-choice"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    target="_blank"
                                                                                                                >
                                                                                                                    http://www.networkadvertising.org/mobile-choice
                                                                                                                </a>
                                                                                                            </span>
                                                                                                            .
                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                            For more information on the
                                                                                                            privacy practices of Google,
                                                                                                            please visit the{" "}
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "#7ab945"
                                                                                                                }}
                                                                                                            >
                                                                                                                <a
                                                                                                                    data-custom-class="link"
                                                                                                                    href="https://policies.google.com/privacy"
                                                                                                                    rel="noopener noreferrer"
                                                                                                                    target="_blank"
                                                                                                                >
                                                                                                                    Google Privacy &amp; Terms
                                                                                                                    page
                                                                                                                </a>
                                                                                                            </span>
                                                                                                            .
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                        <span
                                                                                                            style={{ fontSize: 15 }}
                                                                                                        />
                                                                                                    </bdt>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                            fontSize: 15
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span data-custom-class="body_text">
                                                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                            fontSize: 15
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                color:
                                                                                                                                    "rgb(89, 89, 89)",
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize: 15
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                                <bdt className="block-component" />
                                                                                                                                            </span>
                                                                                                                                            <bdt className="block-component">
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <bdt className="block-component" />
                                                                                                                                                </span>
                                                                                                                                            </bdt>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="inforetain"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(127, 127, 127)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        id="control"
                                                                                                                        style={{
                                                                                                                            color: "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                5. HOW LONG DO WE
                                                                                                                                KEEP YOUR
                                                                                                                                INFORMATION?
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <strong>
                                                                                                                    <em>In Short:&nbsp;</em>
                                                                                                                </strong>
                                                                                                                <em>
                                                                                                                    We keep your information
                                                                                                                    for as long as necessary
                                                                                                                    to{" "}
                                                                                                                    <bdt className="block-component" />
                                                                                                                    fulfill
                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                    the purposes outlined in
                                                                                                                    this privacy notice unless
                                                                                                                    otherwise required by law.
                                                                                                                </em>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                We will only keep your
                                                                                                                personal information for as
                                                                                                                long as it is necessary for
                                                                                                                the purposes set out in this
                                                                                                                privacy notice, unless a
                                                                                                                longer retention period is
                                                                                                                required or permitted by law
                                                                                                                (such as tax, accounting, or
                                                                                                                other legal requirements).
                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                No purpose in this notice
                                                                                                                will require us keeping your
                                                                                                                personal information for
                                                                                                                longer than{" "}
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <bdt className="block-component" />
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                                <bdt className="block-component" />
                                                                                                                the period of time in which
                                                                                                                users have an account with
                                                                                                                us
                                                                                                                <bdt className="block-component" />
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            <bdt className="else-block" />
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                                .
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                When we have no ongoing
                                                                                                                legitimate business need to
                                                                                                                process your personal
                                                                                                                information, we will either
                                                                                                                delete or{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                anonymize
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                such information, or, if
                                                                                                                this is not possible (for
                                                                                                                example, because your
                                                                                                                personal information has
                                                                                                                been stored in backup
                                                                                                                archives), then we will
                                                                                                                securely store your personal
                                                                                                                information and isolate it
                                                                                                                from any further processing
                                                                                                                until deletion is possible.
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="block-component" />
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="infosafe"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(127, 127, 127)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        id="control"
                                                                                                                        style={{
                                                                                                                            color: "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                6. HOW DO WE KEEP
                                                                                                                                YOUR INFORMATION
                                                                                                                                SAFE?
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <strong>
                                                                                                                    <em>In Short:&nbsp;</em>
                                                                                                                </strong>
                                                                                                                <em>
                                                                                                                    We aim to protect your
                                                                                                                    personal information
                                                                                                                    through a system of{" "}
                                                                                                                    <bdt className="block-component" />
                                                                                                                    organizational
                                                                                                                    <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                    and technical security
                                                                                                                    measures.
                                                                                                                </em>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                We have implemented
                                                                                                                appropriate and reasonable
                                                                                                                technical and{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                organizational
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                security measures designed
                                                                                                                to protect the security of
                                                                                                                any personal information we
                                                                                                                process. However, despite
                                                                                                                our safeguards and efforts
                                                                                                                to secure your information,
                                                                                                                no electronic transmission
                                                                                                                over the Internet or
                                                                                                                information storage
                                                                                                                technology can be guaranteed
                                                                                                                to be 100% secure, so we
                                                                                                                cannot promise or guarantee
                                                                                                                that hackers,
                                                                                                                cybercriminals, or other{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                unauthorized
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                third parties will not be
                                                                                                                able to defeat our security
                                                                                                                and improperly collect,
                                                                                                                access, steal, or modify
                                                                                                                your information. Although
                                                                                                                we will do our best to
                                                                                                                protect your personal
                                                                                                                information, transmission of
                                                                                                                personal information to and
                                                                                                                from our Services is at your
                                                                                                                own risk. You should only
                                                                                                                access the Services within a
                                                                                                                secure environment.
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component" />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="infominors"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(127, 127, 127)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        id="control"
                                                                                                                        style={{
                                                                                                                            color: "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                7. DO WE COLLECT
                                                                                                                                INFORMATION FROM
                                                                                                                                MINORS?
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <strong>
                                                                                                                    <em>In Short:</em>
                                                                                                                </strong>
                                                                                                                <em>
                                                                                                                    &nbsp;We do not knowingly
                                                                                                                    collect data from or
                                                                                                                    market to{" "}
                                                                                                                    <bdt className="block-component" />
                                                                                                                    children under 18 years of
                                                                                                                    age
                                                                                                                    <bdt className="else-block" />
                                                                                                                    .
                                                                                                                </em>
                                                                                                                <bdt className="block-component" />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                We do not knowingly collect,
                                                                                                                solicit data from, or market
                                                                                                                to children under 18 years
                                                                                                                of age, nor do we knowingly
                                                                                                                sell such personal
                                                                                                                information. By using the
                                                                                                                Services, you represent that
                                                                                                                you are at least 18 or that
                                                                                                                you are the parent or
                                                                                                                guardian of such a minor and
                                                                                                                consent to such minor
                                                                                                                dependent’s use of the
                                                                                                                Services. If we learn that
                                                                                                                personal information from
                                                                                                                users less than 18 years of
                                                                                                                age has been collected, we
                                                                                                                will deactivate the account
                                                                                                                and take reasonable measures
                                                                                                                to promptly delete such data
                                                                                                                from our records. If you
                                                                                                                become aware of any data we
                                                                                                                may have collected from
                                                                                                                children under age 18,
                                                                                                                please contact us at{" "}
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component" />
                                                                                                                        <bdt className="question">
                                                                                                                            info@assetsglobal.in
                                                                                                                        </bdt>
                                                                                                                        <bdt className="else-block" />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                                .
                                                                                                            </span>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="else-block">
                                                                                                                    <bdt className="block-component" />
                                                                                                                </bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="privacyrights"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(127, 127, 127)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        id="control"
                                                                                                                        style={{
                                                                                                                            color: "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                8. WHAT ARE YOUR
                                                                                                                                PRIVACY RIGHTS?
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <strong>
                                                                                                                    <em>In Short:</em>
                                                                                                                </strong>
                                                                                                                <em>
                                                                                                                    &nbsp;
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <em>
                                                                                                                                    <bdt className="block-component" />
                                                                                                                                </em>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                        &nbsp;
                                                                                                                    </span>
                                                                                                                    You may review, change, or
                                                                                                                    terminate your account at
                                                                                                                    any time, depending on
                                                                                                                    your country, province, or
                                                                                                                    state of residence.
                                                                                                                </em>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <bdt className="block-component">
                                                                                                                            <bdt className="block-component" />
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="withdrawconsent"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <strong>
                                                                                                                    <u>
                                                                                                                        Withdrawing your
                                                                                                                        consent:
                                                                                                                    </u>
                                                                                                                </strong>{" "}
                                                                                                                If we are relying on your
                                                                                                                consent to process your
                                                                                                                personal information,
                                                                                                                <bdt className="block-component" />{" "}
                                                                                                                which may be express and/or
                                                                                                                implied consent depending on
                                                                                                                the applicable law,
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                you have the right to
                                                                                                                withdraw your consent at any
                                                                                                                time. You can withdraw your
                                                                                                                consent at any time by
                                                                                                                contacting us by using the
                                                                                                                contact details provided in
                                                                                                                the section{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                "
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <a
                                                                                                        data-custom-class="link"
                                                                                                        href="#contact"
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "#7ab945"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "#7ab945"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    HOW CAN YOU CONTACT US
                                                                                                                    ABOUT THIS NOTICE?
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                "
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                below
                                                                                                                <bdt className="block-component" />
                                                                                                                .
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            However, please note that this
                                                                                                            will not affect the lawfulness
                                                                                                            of the processing before its
                                                                                                            withdrawal nor,
                                                                                                            <bdt className="block-component" />{" "}
                                                                                                            when applicable law allows,
                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                            will it affect the processing
                                                                                                            of your personal information
                                                                                                            conducted in reliance on
                                                                                                            lawful processing grounds
                                                                                                            other than consent.
                                                                                                            <bdt className="block-component" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <strong>
                                                                                                                <u>
                                                                                                                    Opting out of marketing
                                                                                                                    and promotional
                                                                                                                    communications:
                                                                                                                </u>
                                                                                                            </strong>
                                                                                                            <strong>&nbsp;</strong>You can
                                                                                                            unsubscribe from our marketing
                                                                                                            and promotional communications
                                                                                                            at any time by
                                                                                                            <bdt className="block-component" />{" "}
                                                                                                            clicking on the unsubscribe
                                                                                                            link in the emails that we
                                                                                                            send,
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                            <bdt className="block-component" />{" "}
                                                                                                            replying{" "}
                                                                                                            <bdt className="block-component" />
                                                                                                            "STOP" or "UNSUBSCRIBE"
                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                            to the SMS messages that we
                                                                                                            send,
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                            <bdt className="block-component" />{" "}
                                                                                                            or by contacting us using the
                                                                                                            details provided in the
                                                                                                            section{" "}
                                                                                                            <bdt className="block-component" />
                                                                                                            "
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <a
                                                                                                        data-custom-class="link"
                                                                                                        href="#contact"
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "#7ab945",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                HOW CAN YOU CONTACT US ABOUT
                                                                                                                THIS NOTICE?
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </a>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <bdt className="block-component" />
                                                                                                            "
                                                                                                            <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                            below. You will then be
                                                                                                            removed from the marketing
                                                                                                            lists. However, we may still
                                                                                                            communicate with you — for
                                                                                                            example, to send you
                                                                                                            service-related messages that
                                                                                                            are necessary for the
                                                                                                            administration and use of your
                                                                                                            account, to respond to service
                                                                                                            requests, or for other
                                                                                                            non-marketing purposes.
                                                                                                        </span>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <bdt className="statement-end-if-in-editor" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <bdt className="block-component">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text" />
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="heading_2">
                                                                                                            <strong>
                                                                                                                Account Information
                                                                                                            </strong>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            If you would at any time like
                                                                                                            to review or change the
                                                                                                            information in your account or
                                                                                                            terminate your account, you
                                                                                                            can:
                                                                                                            <bdt className="forloop-component" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <ul>
                                                                                                    <li
                                                                                                        data-custom-class="body_text"
                                                                                                        style={{ lineHeight: "1.5" }}
                                                                                                    >
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                                style={{ fontSize: 15 }}
                                                                                                            >
                                                                                                                <bdt className="question">
                                                                                                                    Log in to your account
                                                                                                                    settings and update your
                                                                                                                    user account.
                                                                                                                </bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <bdt className="forloop-component" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <ul>
                                                                                                    <li
                                                                                                        data-custom-class="body_text"
                                                                                                        style={{ lineHeight: "1.5" }}
                                                                                                    >
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                                style={{ fontSize: 15 }}
                                                                                                            >
                                                                                                                <bdt className="question">
                                                                                                                    Contact us using the
                                                                                                                    contact information
                                                                                                                    provided.
                                                                                                                </bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </li>
                                                                                                </ul>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <bdt className="forloop-component" />
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            Upon your request to terminate
                                                                                                            your account, we will
                                                                                                            deactivate or delete your
                                                                                                            account and information from
                                                                                                            our active databases. However,
                                                                                                            we may retain some information
                                                                                                            in our files to prevent fraud,
                                                                                                            troubleshoot problems, assist
                                                                                                            with any investigations,
                                                                                                            enforce our legal terms and/or
                                                                                                            comply with applicable legal
                                                                                                            requirements.
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text" />
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize: 15
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                color:
                                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <bdt className="block-component" />
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <strong>
                                                                                                                    <u>
                                                                                                                        Cookies and similar
                                                                                                                        technologies:
                                                                                                                    </u>
                                                                                                                </strong>{" "}
                                                                                                                Most Web browsers are set to
                                                                                                                accept cookies by default.
                                                                                                                If you prefer, you can
                                                                                                                usually choose to set your
                                                                                                                browser to remove cookies
                                                                                                                and to reject cookies. If
                                                                                                                you choose to remove cookies
                                                                                                                or reject cookies, this
                                                                                                                could affect certain
                                                                                                                features or services of our
                                                                                                                Services.{" "}
                                                                                                                <bdt className="block-component">
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                color:
                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <bdt className="block-component" />
                                                                                                                                For further
                                                                                                                                information, please
                                                                                                                                see our Cookie
                                                                                                                                Notice:{" "}
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "#7ab945"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <bdt className="question">
                                                                                                                                        <a
                                                                                                                                            href="https://assetsglobal.in/cookies"
                                                                                                                                            target="_blank"
                                                                                                                                            data-custom-class="link"
                                                                                                                                        >
                                                                                                                                            https://assetsglobal.in/cookies
                                                                                                                                        </a>
                                                                                                                                    </bdt>
                                                                                                                                </span>
                                                                                                                                .
                                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span
                                                                                                                                            style={{
                                                                                                                                                fontSize: 15
                                                                                                                                            }}
                                                                                                                                        >
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span
                                                                                                                                                    style={{
                                                                                                                                                        fontSize: 15
                                                                                                                                                    }}
                                                                                                                                                >
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </bdt>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <bdt className="block-component">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text" />
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            If you have questions or
                                                                                                            comments about your privacy
                                                                                                            rights, you may email us at{" "}
                                                                                                            <bdt className="question">
                                                                                                                info@assetsglobal.in
                                                                                                            </bdt>
                                                                                                            .
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <bdt className="statement-end-if-in-editor">
                                                                                                        <span style={{ fontSize: 15 }}>
                                                                                                            <span data-custom-class="body_text" />
                                                                                                        </span>
                                                                                                    </bdt>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="DNT"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(127, 127, 127)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        id="control"
                                                                                                                        style={{
                                                                                                                            color: "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                9. CONTROLS FOR
                                                                                                                                DO-NOT-TRACK
                                                                                                                                FEATURES
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                Most web browsers and some
                                                                                                                mobile operating systems and
                                                                                                                mobile applications include
                                                                                                                a Do-Not-Track (
                                                                                                                <bdt className="block-component" />
                                                                                                                "DNT"
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                ) feature or setting you can
                                                                                                                activate to signal your
                                                                                                                privacy preference not to
                                                                                                                have data about your online
                                                                                                                browsing activities
                                                                                                                monitored and collected. At
                                                                                                                this stage, no uniform
                                                                                                                technology standard for{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                recognizing
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                and implementing DNT signals
                                                                                                                has been{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                finalized
                                                                                                                <bdt className="statement-end-if-in-editor" />
                                                                                                                . As such, we do not
                                                                                                                currently respond to DNT
                                                                                                                browser signals or any other
                                                                                                                mechanism that automatically
                                                                                                                communicates your choice not
                                                                                                                to be tracked online. If a
                                                                                                                standard for online tracking
                                                                                                                is adopted that we must
                                                                                                                follow in the future, we
                                                                                                                will inform you about that
                                                                                                                practice in a revised
                                                                                                                version of this privacy
                                                                                                                notice.
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <bdt className="block-component">
                                                                                                        <span
                                                                                                            style={{ fontSize: 15 }}
                                                                                                        />
                                                                                                    </bdt>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <bdt className="block-component">
                                                                                                        <span
                                                                                                            style={{ fontSize: 15 }}
                                                                                                        />
                                                                                                    </bdt>
                                                                                                    <bdt className="block-component">
                                                                                                        <span
                                                                                                            style={{ fontSize: 15 }}
                                                                                                        />
                                                                                                    </bdt>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="policyupdates"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(127, 127, 127)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        id="control"
                                                                                                                        style={{
                                                                                                                            color: "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                10. DO WE MAKE
                                                                                                                                UPDATES TO THIS
                                                                                                                                NOTICE?
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <em>
                                                                                                                    <strong>
                                                                                                                        In Short:&nbsp;
                                                                                                                    </strong>
                                                                                                                    Yes, we will update this
                                                                                                                    notice as necessary to
                                                                                                                    stay compliant with
                                                                                                                    relevant laws.
                                                                                                                </em>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                We may update this privacy
                                                                                                                notice from time to time.
                                                                                                                The updated version will be
                                                                                                                indicated by an updated{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                "Revised"
                                                                                                                <bdt className="statement-end-if-in-editor" />{" "}
                                                                                                                date at the top of this
                                                                                                                privacy notice. If we make
                                                                                                                material changes to this
                                                                                                                privacy notice, we may
                                                                                                                notify you either by
                                                                                                                prominently posting a notice
                                                                                                                of such changes or by
                                                                                                                directly sending you a
                                                                                                                notification. We encourage
                                                                                                                you to review this privacy
                                                                                                                notice frequently to be
                                                                                                                informed of how we are
                                                                                                                protecting your information.
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="contact"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(127, 127, 127)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        id="control"
                                                                                                                        style={{
                                                                                                                            color: "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                11. HOW CAN YOU
                                                                                                                                CONTACT US ABOUT
                                                                                                                                THIS NOTICE?
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                If you have questions or
                                                                                                                comments about this notice,
                                                                                                                you may{" "}
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color:
                                                                                                                            "rgb(89, 89, 89)",
                                                                                                                        fontSize: 15
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component">
                                                                                                                            <bdt className="block-component" />
                                                                                                                        </bdt>
                                                                                                                        email us at{" "}
                                                                                                                        <bdt className="question">
                                                                                                                            info@assetsglobal.in
                                                                                                                            or&nbsp;
                                                                                                                        </bdt>
                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                            <bdt className="block-component" />
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: 15,
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span data-custom-class="body_text">
                                                                                                                            contact us by post at:
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                color:
                                                                                                                                    "rgb(89, 89, 89)"
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <bdt className="question">
                                                                                                                                    Assets Global
                                                                                                                                </bdt>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span data-custom-class="body_text">
                                                                                                                        <bdt className="block-component" />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <bdt className="question">
                                                                                                                50, 1st floor, MM Road,{" "}
                                                                                                            </bdt>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <bdt className="block-component" />
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        data-custom-class="body_text"
                                                                                                        style={{ fontSize: 15 }}
                                                                                                    >
                                                                                                        <bdt className="question">
                                                                                                            Pulikeshi Nagar, Frazer Town
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        style={{ fontSize: 15 }}
                                                                                                                    >
                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </bdt>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <bdt className="question">
                                                                                                                Bengaluru
                                                                                                            </bdt>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                >
                                                                                                                    <bdt className="block-component" />
                                                                                                                    ,{" "}
                                                                                                                    <bdt className="question">
                                                                                                                        Karnataka
                                                                                                                    </bdt>
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                    <bdt className="block-component" />{" "}
                                                                                                                    <bdt className="question">
                                                                                                                        560005
                                                                                                                    </bdt>
                                                                                                                    <bdt className="statement-end-if-in-editor" />
                                                                                                                    <bdt className="block-component" />
                                                                                                                    <bdt className="block-component" />
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span style={{ fontSize: 15 }}>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                                style={{ fontSize: 15 }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <bdt className="block-component" />
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                            <bdt className="question">
                                                                                                                India
                                                                                                            </bdt>
                                                                                                            <span
                                                                                                                style={{ fontSize: 15 }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    fontSize: 15
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span data-custom-class="body_text">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            color:
                                                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </bdt>
                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            "rgb(89, 89, 89)"
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            fontSize: 15
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <bdt className="statement-end-if-in-editor" />
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <span data-custom-class="body_text">
                                                                                                            <span
                                                                                                                style={{ fontSize: 15 }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <bdt className="statement-end-if-in-editor">
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    color:
                                                                                                                                        "rgb(89, 89, 89)"
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        fontSize: 15
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span data-custom-class="body_text">
                                                                                                                                        <bdt className="block-component">
                                                                                                                                            <bdt className="block-component" />
                                                                                                                                        </bdt>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </bdt>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                            <bdt className="block-component">
                                                                                                                <span
                                                                                                                    style={{ fontSize: 15 }}
                                                                                                                />
                                                                                                            </bdt>
                                                                                                            <span
                                                                                                                style={{ fontSize: 15 }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "rgb(89, 89, 89)",
                                                                                                                            fontSize: 15
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span data-custom-class="body_text">
                                                                                                                                <bdt className="statement-end-if-in-editor">
                                                                                                                                    <bdt className="block-component" />
                                                                                                                                </bdt>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div
                                                                                                    id="request"
                                                                                                    style={{ lineHeight: "1.5" }}
                                                                                                >
                                                                                                    <span
                                                                                                        style={{
                                                                                                            color: "rgb(127, 127, 127)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                color: "rgb(89, 89, 89)",
                                                                                                                fontSize: 15
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color: "rgb(89, 89, 89)"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color: "rgb(89, 89, 89)"
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span
                                                                                                                        id="control"
                                                                                                                        style={{
                                                                                                                            color: "rgb(0, 0, 0)"
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <strong>
                                                                                                                            <span data-custom-class="heading_1">
                                                                                                                                12. HOW CAN YOU
                                                                                                                                REVIEW, UPDATE, OR
                                                                                                                                DELETE THE DATA WE
                                                                                                                                COLLECT FROM YOU?
                                                                                                                            </span>
                                                                                                                        </strong>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <br />
                                                                                                </div>
                                                                                                <div style={{ lineHeight: "1.5" }}>
                                                                                                    <span
                                                                                                        style={{
                                                                                                            fontSize: 15,
                                                                                                            color: "rgb(89, 89, 89)"
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color: "rgb(89, 89, 89)"
                                                                                                            }}
                                                                                                        >
                                                                                                            <span data-custom-class="body_text">
                                                                                                                <bdt className="block-component" />
                                                                                                                You have the right to
                                                                                                                request access to the
                                                                                                                personal information we
                                                                                                                collect from you, details
                                                                                                                about how we have processed
                                                                                                                it, correct inaccuracies, or
                                                                                                                delete your personal
                                                                                                                information. You may also
                                                                                                                have the right to withdraw
                                                                                                                your consent to our
                                                                                                                processing of your personal
                                                                                                                information. These rights
                                                                                                                may be limited in some
                                                                                                                circumstances by applicable
                                                                                                                law. To request to review,
                                                                                                                update, or delete your
                                                                                                                personal information, please{" "}
                                                                                                                <bdt className="block-component" />
                                                                                                                fill out and submit a&nbsp;
                                                                                                            </span>
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    color: "#7ab945"
                                                                                                                }}
                                                                                                            >
                                                                                                                <span data-custom-class="body_text">
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            color:
                                                                                                                                "#7ab945",
                                                                                                                            fontSize: 15
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <a
                                                                                                                            data-custom-class="link"
                                                                                                                            href="https://app.termly.io/notify/36536147-0581-4733-ab97-8f6860f48065"
                                                                                                                            rel="noopener noreferrer"
                                                                                                                            target="_blank"
                                                                                                                        >
                                                                                                                            data subject access
                                                                                                                            request
                                                                                                                        </a>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                            <bdt className="block-component">
                                                                                                                <span data-custom-class="body_text" />
                                                                                                            </bdt>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                    <span data-custom-class="body_text">
                                                                                                        .
                                                                                                    </span>
                                                                                                </div>
                                                                                                <style
                                                                                                    dangerouslySetInnerHTML={{
                                                                                                        __html:
                                                                                                            "\n      ul {\n        list-style-type: square;\n      }\n      ul > li > ul {\n        list-style-type: circle;\n      }\n      ul > li > ul > li > ul {\n        list-style-type: square;\n      }\n      ol li {\n        font-family: Arial ;\n      }\n    "
                                                                                                    }}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default PrivacyPolicy